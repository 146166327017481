import { post } from '@/scripts/http';
import {ExListParams} from "./type/ex-type"
const islogin = localStorage.getItem('token') ? 'auth' : "api";

export default {
    ExCourseURL: `/zone/course/${islogin}/excellent/`,
    MoocCourseURL: `/zone/course/${islogin}/mooc/`,
    FavotitesURL: `/zone/course/${islogin}/favorites/`,
    // 获取课程列表
    async GetExCourseList(data:ExListParams) {
        const res = post(`${this.ExCourseURL}list`, data)
        return res;
    },
    // 获取MOOC列表
    async GetMoocList(data:any) {
        const res = post(`${this.MoocCourseURL}list`, data)
        return res;
    },
    // 获取MOOC详情
    async GetMoocDetail(data:any) {
        const res = post(`${this.MoocCourseURL}view`, data)
        return res;
    },
    // 获取MOOC详情目录
    async GetContextList(data:any) {
        const res = post(`${this.MoocCourseURL}content`, data)
        return res;
    },
    // 获取课堂班级
    async GetClassList(data:any) {
        const res = post(`${this.MoocCourseURL}class/list`, data)
        return res;
    },
    // 加入MOOC班级
    async JoinMoocClass(data:any) {
        const res = post(`${this.MoocCourseURL}class/member/join`, data)
        return res;
    },
     // 加入MOOC班级-通过邀请码
     async JoinMoocClassByCode(data:any) {
        const res = post(`${this.MoocCourseURL}class/member/joinEx`, data)
        return res;
    },
     // 加入MOOC班级-人满申请加入
     async ApplyMooc(data:any) {
        const res = post(`${this.MoocCourseURL}class/member/apply`, data)
        return res;
    },
     // 活动详情
     async GetActionDetail(data:any) {
        const res = post(`${this.MoocCourseURL}chapter/period/action/view`, data)
        return res;
    },
     //  查看详情
    async ViewJob(data:any) {
        const res = post(`${this.MoocCourseURL}job/view`, data)
        return res;
    },
     //  提交活动
    async SubmitJob(data:any) {
        const res = post(`${this.MoocCourseURL}job/submit`, data)
        return res;
    },
     // 我的收藏
     async MyLiekCourse(data:any) {
        const res = post(`${this.FavotitesURL}list`, data)
        return res;
    },
     
     // 收藏课程
     async SaveLikeCourse(data:any) {
        const res = post(`${this.FavotitesURL}save`, data)
        return res;
    },
     // 取消收藏课程
     async DeleteLikeCourse(data:any) {
        const res = post(`${this.FavotitesURL}delete`, data)
        return res;
    },
     

}