<template>
  <div class="rec_list">
    <el-tabs
      v-model="activeName"
      @tab-click="handleTabClick"
      class="net_class_tabs"
    >
      <el-tab-pane label="精品课程" name="1">
        <div v-if="activeName === '1'">
          <div class="select-con">
            <Select @getData="getParams" :isCourse="true"></Select>
          </div>
          <div class="result">
            筛选结果
            <label>{{ total }}</label>
            条
          </div>
          <div class="course_list">
            <Course :arr="excList"></Course>
          </div>
        </div>

        <div class="pagination">
          <ListPage
            v-if="total"
            v-model:curPage="queryForm.pageCurrent"
            @pageChanged="getExCourseList"
            :total="total"
            :pSize="queryForm.pageSize"
          ></ListPage>
        </div>
      </el-tab-pane>
      <el-tab-pane label="精品微课" name="2">
        <div v-if="activeName === '2'">
          <Select
            @getData="getParams"
            :isCourse="true"
            style="margin-top: -12px"
          ></Select>
          <div class="result">
            筛选结果
            <label>{{ total }}</label>
            条
          </div>
          <div class="course_list">
            <Course :arr="exMicroList"></Course>
          </div>
          <div class="pagination">
            <ListPage
              v-if="total"
              v-model:curPage="queryForm.pageCurrent"
              @pageChanged="getMicroCourseList"
              :total="total"
              :pSize="queryForm.pageSize"
            ></ListPage>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="在线MOOC" name="3">
        <div v-if="activeName === '3'">
          <Select
            @getData="getParams"
            :isCourse="true"
            style="margin-top: -12px"
          ></Select>
          <div class="result">
            筛选结果
            <label>{{ total }}</label>
            条
          </div>
          <div class="course_list">
            <Course :arr="moocList" classify="mooc"></Course>
          </div>
          <div class="pagination">
            <ListPage
              v-if="total"
              v-model:curPage="queryForm.pageCurrent"
              @pageChanged="getMoocList"
              :total="total"
              :pSize="queryForm.pageSize"
            ></ListPage>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Select from "@/components/Select";
import Course from "./components/Course";
import ListPage from "./../../components/ListPage/index";
import commonApi from "@/api/common";
import { getAssetsFile, disturbList } from "@/scripts/utils";
import courseApi from "@/api/course";
export default {
  components: {
    Select,
    Course,
    ListPage,
  },
  data() {
    return {
      activeName: "1",
      queryForm: {
        pageCurrent: 1,
        pageSize: 12,
      },
      total: 0,
      // 静态数据
      recList: [],
      // 精品课堂
      excList: [],
      // 精品微课
      exMicroList: [],
      // mooc列表
      moocList: [],
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(newVal) {
        this.handleGetList(newVal.params.type);
      },
    },
  },
  mounted() {
    const type = this.$route.params.type;
    this.handleGetList(type);
  },
  methods: {
    // async loadData() {
    //   const res = await loadData(
    //     this.grade,
    //     this.subject,
    //     this.queryForm.pageCurrent
    //   );
    //   if (res) {
    //     this.total = res.total;
    //     this.recList = res.data;
    //     this.excList = this.recList;
    //     this.exMicroList = res.data;
    //     this.moocList = this.recList;
    //   }
    // },
    handleGetList(type) {
      this.activeName = type;
      switch (type) {
        case "1": {
          this.getExCourseList();
          break;
        }
        case "2": {
          this.getMicroCourseList();
          break;
        }
        case "3": {
          this.getMoocList();
          break;
        }
      }
    },
    handleTabClick() {
      if (this.$route.params !== this.activeName) {
        const path = `/rec/list/${this.activeName}`;
        this.$router.push(path);
      }
      this.queryForm.pageCurrent = 1;
    },
    // 精品课程列表
    async getExCourseList() {
      const res = await courseApi.GetExCourseList({
        ...this.queryForm,
        mode: parseInt(this.activeName),
      });
      this.excList = res.data.list;
      if (res.data.list.length < this.queryForm.pageSize) {
        this.loadAssets();
      }
      this.total = this.excList.length;
    },
    //  微课列表
    async getMicroCourseList() {
      const res = await courseApi.GetExCourseList({
        ...this.queryForm,
        mode: parseInt(this.activeName),
      });
      this.exMicroList = res.data.list;
      if (res.data.list.length < this.queryForm.pageSize) {
        this.loadAssets();
      }
      this.total = this.exMicroList.length;
    },
    // mooc列表
    async getMoocList() {
      const res = await courseApi.GetMoocList(this.queryForm);
      this.moocList = res.data.list.map((item) => {
        const random = Math.floor(Math.random() * 10) + 1;
        let ava = "";
        if (window.Config.isDemostrate) {
          ava = getAssetsFile(`teacher${random}.png`, "png");
        } else {
          ava = item.avatar;
        }
        return {
          ...item,
          lecturerPhoto: ava,
        };
      });
      if (res.data.list.length < this.queryForm.pageSize) {
        this.loadAssets();
      }
      this.total = res.data.list.length;
    },
    // 添加加数据
    async loadAssets() {
      let assetsList = [];
      switch (this.activeName) {
        case "1": {
          const res = await commonApi.GetDocData("fine");
          if (res && res.code === 200) {
            assetsList = res.data.fine.map((item) => {
              const imgSuffix = item.imgSrc.split(".")[1];
              const avaSuffix = item.avatar.split(".")[1];
              return {
                ...item,
                lecturerName: item.teacher,
                cover: getAssetsFile(item.imgSrc, imgSuffix),
                lecturerPhoto: getAssetsFile(item.avatar, avaSuffix),
              };
            });
            const num = this.queryForm.pageSize - this.excList.length;
            this.excList = this.excList.concat(
              disturbList(assetsList.splice(0, num))
            );

            this.total = this.excList.length;
          }
          break;
        }
        case "2": {
          const res = await commonApi.GetDocData("micro");
          if (res && res.code === 200) {
            assetsList = res.data.micro.map((item) => {
              const imgSuffix = item.imgSrc.split(".")[1];
              const avaSuffix = item.avatar.split(".")[1];
              return {
                ...item,
                lecturerName: item.teacher,
                cover: getAssetsFile(item.imgSrc, imgSuffix),
                lecturerPhoto: getAssetsFile(item.avatar, avaSuffix),
              };
            });
            const num = this.queryForm.pageSize - this.exMicroList.length;
            this.exMicroList = this.exMicroList.concat(
              disturbList(assetsList.splice(0, num))
            );
            this.total = this.excList.length;
          }
          break;
        }
        case "3": {
          const res = await commonApi.GetDocData("mooc");
          if (res && res.code === 200) {
            assetsList = res.data.mooc.map((item) => {
              const imgSuffix = item.imgSrc.split(".")[1];
              const avaSuffix = item.avatar.split(".")[1];
              return {
                ...item,
                lecturerPhoto: getAssetsFile(item.avatar, avaSuffix),
                lecturerName: item.teacher,
                cover: getAssetsFile(item.imgSrc, imgSuffix),
              };
            });
            this.moocList = this.moocList.concat(disturbList(assetsList));
          }
        }
      }
    },

    // 获取搜索到的参数
    getParams(obj) {
      this.queryForm.subjectName = obj.subjectName;
      this.queryForm.gradeName = obj.gradeName;
      this.queryForm.schoolName = obj.schoolName;
      this.queryForm.teacherName = obj.lecturerName;
      this.handleTabClick();
    },
  },
};
</script>

<style lang="scss">
.rec_list {
  width: 1200px;
  margin: 0 auto;
  min-height: calc(100% - 180px);
  .net_class_tabs {
    .el-tabs__header {
      .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
          .el-tabs__nav {
            .el-tabs__active-bar {
              background-color: #f27200;
            }
            .el-tabs__item {
              color: #666;
              font-size: 12px;
            }
            .el-tabs__item:hover {
              color: #f27200;
            }
            .is-active {
              color: #f27200;
            }
          }
        }
      }
    }
  }
  .result {
    font-size: 12px;
    color: #666;
    margin-bottom: 20px;
    label {
      color: #f27200;
    }
  }
  .course_list {
    width: 100%;
    margin-bottom: 60px;
  }
  .pagination {
    text-align: center;
    margin-bottom: 60px;
  }
}
.select-con {
  margin-top: -40px;
}
</style>
