<template>
  <el-pagination
    class="list-page"
    background
    layout="prev, pager, next"
    :pager-count="7"
    prev-text="上一页"
    next-text="下一页"
    v-model:currentPage="page"
    :page-size="pSize"
    :total="total"
    @size-change="pageSizeChanged"
    @current-change="pageChanged"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    // 当前第几页
    curPage: {
      type: Number,
      default: 1,
    },
    pSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    page: {
      get() {
        return this.curPage;
      },
      set(val) {
        this.$emit("update:curPage", val);
      },
    },
    pageSize: {
      get() {
        return this.pSize;
      },
      set(val) {
        this.$emit("update:curPage", 1);
        this.$emit("update:pSize", val);
      },
    },
  },
  methods: {
    /**
     * 分页大小改变时会触发
     */
    pageSizeChanged(val) {
      this.$emit("pageSizeChanged", val);
    },
    /**
     * 切换分页时触发
     * @param {number} val 新的页码
     */
    pageChanged(val) {
      const obj = { pageCurrent: val, pageSize: this.pSize };
      this.$emit("pageChanged", obj);
    },
  },
};
</script>

<style lang="scss">
//分页
.list-page.el-pagination {
  padding: 10px 0;
  text-align: center;
  .el-pager li {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #eeeeee;
  }
  .active {
    background-color: #f27200 !important;
  }
  .btn-prev,
  .btn-next {
    width: 60px;
    height: 32px;
    background: #ffffff !important;
    border: 1px solid #eeeeee;
  }
}
</style>
