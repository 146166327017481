<template>
  <div>
    <div class="root" v-if="arr.length">
      <div
        class="recommend_course"
        v-for="(item, index) in arr"
        :key="index"
        @click="viewDetail(item.courseId)"
      >
        <img
          class="course_img"
          :src="
            item.cover ? item.cover : '/img/中考现代文课程详解.9df76aa6.png'
          "
        />
        <div class="recommend_course_name">{{ item.title }}</div>
        <div class="recommend_course_tags">
          <span v-for="elem in item.knowledgeJson" :key="elem.chapterId">
            {{ elem.name }}
          </span>
        </div>
        <div class="recommend_course_teacher">
          <div>
            <img v-if="item.lecturerPhoto" :src="item.lecturerPhoto" />
            <img v-else src="@/assets/imgs/default_avatar.png" />
            <span>{{ item.lecturerName }}</span>
          </div>
          <div>{{ item.countJoin }}人学习</div>
        </div>
      </div>
    </div>
    <div v-else class="non-class">
      <img src="@/assets/images/wushuju.png" alt="" />
      <div>暂无课程</div>
    </div>
    <Login ref="Login" />
  </div>
</template>

<script>
import Login from "@/components/TopBar/components/LoginDialog.vue";
export default {
  name: "Course",
  props: {
    arr: {
      type: Array,
      default: () => [],
    },
    classify: {
      type: String,
      default: "",
    },
  },
  components: { Login },
  methods: {
    viewDetail(id) {
      if (localStorage.getItem("token")) {
        if (this.classify === "mooc") {
          this.$router.push("/rec/mooc/" + id);
        } else {
          this.$router.push("/rec/detail/" + id);
        }
      } else {
        this.$refs.Login.digShow();
      }
    },
  },
};
</script>

<style lang="scss">
.root {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .recommend_course {
    width: 285px;
    margin-bottom: 20px;
    margin-right: 15px;
    cursor: pointer;
    .course_img {
      width: 100%;
      height: 160px;
      margin-bottom: 10px;
    }
    .recommend_course_name {
      width: calc(280px - 20px);
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: #333;
      margin-bottom: 10px;
    }
    .recommend_course_tags {
      margin-bottom: 10px;
      span {
        background-color: #eeeeee;
        color: #666;
        padding: 3px 5px;
        border-radius: 10px;
        margin-right: 10px;
        font-size: 12px;
      }
    }
    .recommend_course_teacher {
      padding: 0 10px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1) {
        color: #666;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 50%;
        }
      }
      div:nth-child(2) {
        color: #666;
        font-size: 12px;
      }
    }
  }
}
.non-class {
  width: 100%;
  padding-top: 30px;
  font-size: 12px;
  text-align: center;
  color: #dddddd;
  img {
    width: 194px;
    height: 225px;
  }
  div {
    margin-top: 10px;
  }
}
</style>
