
import { defineComponent } from "vue";
import apiSubject from "@/api/subject";
import apiGrade from "@/api/grade";
export default defineComponent({
  props: {
    //是否是未直播
    isLive: {
      type: Boolean,
      default: false,
    },
    // 是否网络课堂页面
    isCourse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null as any,
      selectArr: [
        {
          navName: "直播类型",
          shown: true,
          radios: [
            {
              label: "3",
              name: "全部",
            },
            {
              label: "1",
              name: "直播中",
            },
            {
              label: "2",
              name: "直播已结束",
            },
          ],
        },
        {
          navName: "课堂类型",
          shown: true,
          radios: [
            {
              label: "",
              name: "全部",
            },
            {
              label: "1",
              name: "直播课堂",
            },
            {
              label: "2",
              name: "互动课堂",
            },
          ],
        },
        {
          navName: "年级",
          shown: true,
          radios: [
            {
              label: "all",
              name: "全部",
            },
          ],
        },
        {
          navName: "学科",
          shown: true,
          radios: [],
        },
      ],
      val: ["3", "", "all", "all"],
      sponsorSchool: "",
      sponsorTeacher: "",
      dateRange: [],
      stage: "",
      subjectArr: [],
      totalSubjectArr: [],
    };
  },
  created() {
    if (this.isLive) {
      this.selectArr[0].shown = false;
    }
    if (this.isCourse) {
      this.selectArr[0].shown = false;
      this.selectArr[1].shown = false;
    }
    this.GetDate();
  },
  methods: {
    async GetDate() {
      const resg = await apiGrade.GetGrade();
      const arr: any[] = [
        {
          label: "all",
          name: "全部",
        },
      ];
      let temp: any[] = [];
      let total: any[] = [];
      if (resg.code === 200) {
        resg.data.list.forEach((element: any) => {
          const suj = {
            label: element.gradeName,
            name: element.gradeName,
            stage: element.stage,
          };
          const index = this.selectArr.findIndex((r) => r.navName === "年级");
          this.selectArr[index].radios.push(suj);
        });
      }
      for (let i = 1; i < 4; i++) {
        temp = [];
        const res = await apiSubject.GetSubject({
          stage: i,
        });
        res.data.list.forEach((element: any) => {
          const suj = {
            label: element.subjectName,
            name: element.subjectName,
            stage: i,
          };
          arr.push(suj);
          temp.push(suj);
        });
        total.push(temp);
      }
      const index = this.selectArr.findIndex((r) => r.navName === "学科");
      this.selectArr[index].radios = arr.filter(
        (item, index, origin) =>
          index ===
          origin.findIndex((itemInner) => {
            return itemInner.label === item.label;
          })
      );
      this.subjectArr = total as any;
      this.totalSubjectArr = this.selectArr[index].radios as any;
    },

    GetSubjectList(stage: any) {
      const index = this.selectArr.findIndex((r) => r.navName === "学科");
      const arr: any[] = [
        {
          label: "all",
          name: "全部",
        },
      ];
      if (stage) {
        this.selectArr[index].radios = arr.concat(this.subjectArr[stage - 1]);
      } else {
        this.selectArr[index].radios = this.totalSubjectArr;
      }
    },
    filterchange() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const index = this.selectArr.findIndex((r) => r.navName === "年级");
        let temp = this.selectArr[index].radios.filter(
          (item: any) => item.name === this.val[2]
        )[0] as any;

        let newStage = temp ? temp.stage : "";

        if (newStage !== this.stage) {
          this.GetSubjectList(newStage);
          this.stage = newStage;
        }
        //最大日期为某一天的最后一刻
        let plus = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000;
        const obj = {
          schoolName: this.sponsorSchool,
          lecturerName: this.sponsorTeacher,
          startMax: this.dateRange ? parseInt(this.dateRange[1]) + plus : "",
          startMin: this.dateRange ? parseInt(this.dateRange[0]) : "",
          state: "",
          type: "",
          gradeName: "",
          subjectName: "",
        };
        this.val.forEach((element, index) => {
          if (element !== "all") {
            switch (index) {
              case 0:
                obj.state = this.val[0];
                break;
              case 1:
                obj.type = this.val[1];
                break;
              case 2:
                obj.gradeName = this.val[2];
                break;
              case 3:
                obj.subjectName = this.val[3];
                break;
              default:
                break;
            }
          }
        });

        this.$emit("getData", obj);
      }, 500);
    },
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
});
